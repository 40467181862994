import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../images/around/speelplein.webp";
import img2 from "../../images/around/baldusbeach.jpg";
import img3 from "../../images/around/plopsaland.jpg";
import img4 from "../../images/around/cinema.jpg";
import img5 from "../../images/around/oostduinkerke.jpeg";
import img6 from "../../images/around/noordduinen.jpg";
import img7 from "../../images/around/minigolf.webp";
import { FaLocationDot } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";

const Card = ({
  imgSrc,
  altText,
  title,
  description,
  moreInfoText,
  distance,
  link,
}) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="around-card" data-aos="fade-up">
      <div className="around-card-image">
        <img src={imgSrc} alt={altText} />
      </div>
      <div className="around-card-information">
        <div className="around-card-title">{title}</div>
        <div className="around-card-text">{description}</div>
        <div className="around-card-distance">
          <FaLocationDot />
          {distance}
        </div>
        <a
          href={link}
          target="_blank"
          className="around-card-moreInfo"
          rel="noreferrer"
        >
          {moreInfoText}
        </a>
      </div>
    </div>
  );
};

const Around = () => {
  window.scrollTo(0, 0);

  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="block-information">
        <div className="block-title"> {t("aroundTitle")}</div>
        <div className="block-text">{t("aroundText1")}</div>
      </div>
      <div className="around-title">{t("aroundText2")}</div>
      <div className="around-container">
        <Card
          imgSrc={img2}
          altText={t("aroundCardTitle1")}
          title={t("aroundCardTitle1")}
          description={t("aroundCardText1")}
          distance="300m"
          moreInfoText={t("moreInfo")}
          link="https://baldusbeach.be/"
        />
        <Card
          imgSrc={img6}
          altText={t("aroundCardTitle2")}
          title={t("aroundCardTitle2")}
          description={t("aroundCardText2")}
          distance="1 km"
          moreInfoText={t("moreInfo")}
          link="https://www.natuurenbos.be/natuurgebieden/noordduinen"
        />

        <Card
          imgSrc={img3}
          altText={t("aroundCardTitle3")}
          title={t("aroundCardTitle3")}
          description={t("aroundCardText3")}
          distance="7 km"
          moreInfoText={t("moreInfo")}
          link="https://www.plopsalanddepanne.be/nl"
        />
        <Card
          imgSrc={img5}
          altText={t("aroundCardTitle4")}
          title={t("aroundCardTitle4")}
          description={t("aroundCardText4")}
          distance="750 m"
          moreInfoText={t("moreInfo")}
          link="https://oostduinkerke.com/nl/strand.php"
        />
        <Card
          imgSrc={img4}
          altText={t("aroundCardTitle5")}
          title={t("aroundCardTitle5")}
          description={t("aroundCardText5")}
          distance="850 m"
          moreInfoText={t("moreInfo")}
          link="https://cinemakoksijde.be/nl"
        />
        <Card
          imgSrc={img7}
          altText={t("aroundCardTitle6")}
          title={t("aroundCardTitle6")}
          description={t("aroundCardText6")}
          distance="1 km"
          moreInfoText={t("moreInfo")}
          link="https://www.minigolfpetitbois.com/"
        />
        <Card
          imgSrc={img1}
          altText={t("aroundCardTitle7")}
          title={t("aroundCardTitle7")}
          description={t("aroundCardText7")}
          distance="500m"
          moreInfoText={t("moreInfo")}
          link="https://www.koksijde.be/speelterreinen/"
        />
      </div>
    </div>
  );
};

export default Around;
