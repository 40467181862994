import React from "react";
import img1 from "../../../images/DSCF4685-HDR (Groot).jpg";
import img2 from "../../../images/DSCF4688-HDR (Groot).jpg";
import img3 from "../../../images/DSCF4691-HDR (Groot).jpg";
import img4 from "../../../images/DSCF4694-HDR (Groot).jpg";
import img5 from "../../../images/DSCF4697-HDR (Groot).jpg";
import img6 from "../../../images/DSCF4703-HDR (Groot).jpg";
import img7 from "../../../images/DSCF4706-HDR (Groot).jpg";
import img8 from "../../../images/DSCF4713 (Groot).jpg";
import img9 from "../../../images/DSCF4714 (Groot).jpg";
import img10 from "../../../images/DSCF4715 (Groot).jpg";
import img11 from "../../../images/DSCF4719 (Groot).jpg";
import img12 from "../../../images/DSCF4721-HDR (Groot).jpg";
import img13 from "../../../images/DSCF4724-HDR (Groot).jpg";
import img14 from "../../../images/DSCF4727 (Groot).jpg";
import img15 from "../../../images/DSCF4729 (Groot).jpg";
import img16 from "../../../images/DSCF4730 (Groot).jpeg";
import img17 from "../../../images/DSCF4732 (Groot).jpeg";
import img18 from "../../../images/DSCF4733 (Groot).jpeg";
import img19 from "../../../images/DSCF4735 (Groot).jpeg";

// Import all your images (20 images or more)

const ImageSlider = () => {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
  ];

  return (
    <>
      <div className="slider-container">
        <div className="slider-row">
          {images.map((image, index) => (
            <img key={index} src={image} alt={`slide-${index}`} />
          ))}
          {images.map((image, index) => (
            <img key={`dup-${index}`} src={image} alt={`slide-dup-${index}`} />
          ))}
          {/* Duplicate the images to make the sliding loop smoother */}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
