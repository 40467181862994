import React from "react";
import Landing from "./Landing";
import Welcome from "./Welcome";
import About from "./About";
import ImageSlider from "./ImageSlider";

const Homepage = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Landing />
      <div className="page-divider"></div>;
      <div>
        <Welcome />
        <div className="page-divider"></div>;
        <ImageSlider />
        <About />
      </div>
    </>
  );
};

export default Homepage;
