import React from "react";
import welcomeImage from "../../../images/DSCF4691-HDR (Groot).jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div id="welcome" className="block-container">
      <div className="block-information">
        <div className="block-title">{t("welcome")}</div>
        <div className="block-text">{t("welcomeText1")}</div>
        <div className="block-text">{t("welcomeText2")}</div>
        <div className="button-group top-50">
          <a
            href="https://www.booking.com/hotel/be/gerania.nl.html?label=nl-be-booking-desktop-o71RRfF1_hBX6YxxiIOpfwS652796017152%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atikwd-65526620%3Alp9197508%3Ali%3Adec%3Adm-Share-LcURHf%401733825190&sid=0ee17eb8f6e63580020af01fc5c3cb5e&aid=2311236&ucfs=1&arphpl=1&checkin=2025-02-21&checkout=2025-02-23&dest_id=-1962262&dest_type=city&group_adults=5&req_adults=5&no_rooms=2&group_children=0&req_children=0&hpos=1&hapos=1&sr_order=popularity&srpvid=79a147166d200101&srepoch=1733825202&all_sr_blocks=1315638001_404388877_5_0_0&highlighted_blocks=1315638001_404388877_5_0_0&matching_block_id=1315638001_404388877_5_0_0&sr_pri_blocks=1315638001_404388877_5_0_0__32000&from=searchresults"
            className="button-primary"
            target="_blank"
            rel="noreferrer"
          >
            {t("bookNow")}
          </a>
          <Link to="/around" className="button-third">
            {t("around")}
          </Link>
        </div>
      </div>
      <div className="block-img-container">
        <img src={welcomeImage} alt="gerania-gv02" />
      </div>
    </div>
  );
};

export default Welcome;
