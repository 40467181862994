import React from "react";
import LandingPageImage from "../../../images/DSCF4733 (Groot).jpeg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

const Landing = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="landing-page-container">
        <div className="landing-page-information-container">
          <div className="landing-page-title" data-aos="fade-up">
            {t("landingText")}
          </div>
          <div className="address" data-aos="fade-up">
            {t("address")}
          </div>
        </div>
        <div className="landing-page-buttons" data-aos="fade-up">
          <a
            href="https://www.booking.com/hotel/be/gerania.nl.html?label=nl-be-booking-desktop-o71RRfF1_hBX6YxxiIOpfwS652796017152%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atikwd-65526620%3Alp9197508%3Ali%3Adec%3Adm-Share-LcURHf%401733825190&sid=0ee17eb8f6e63580020af01fc5c3cb5e&aid=2311236&ucfs=1&arphpl=1&checkin=2025-02-21&checkout=2025-02-23&dest_id=-1962262&dest_type=city&group_adults=5&req_adults=5&no_rooms=2&group_children=0&req_children=0&hpos=1&hapos=1&sr_order=popularity&srpvid=79a147166d200101&srepoch=1733825202&all_sr_blocks=1315638001_404388877_5_0_0&highlighted_blocks=1315638001_404388877_5_0_0&matching_block_id=1315638001_404388877_5_0_0&sr_pri_blocks=1315638001_404388877_5_0_0__32000&from=searchresults"
            target="_blank"
            rel="noreferrer"
            className="button-primary"
          >
            {t("bookNow")}
          </a>
          <a href="#welcome" className="button-secondary">
            {t("discover")}
          </a>
        </div>
        <div className="landing-page-img-container">
          <img src={LandingPageImage} alt="gerania-logo" />
        </div>
      </div>
    </>
  );
};

export default Landing;
